import React, { useEffect } from "react"

import { useRouter } from "next/router"

import { Loader } from "@/UI"
import { ROOT_PAGE_PATH } from "@/constants/routes"

const _404 = () => {
  const router = useRouter()
  useEffect(() => {
    router.push(ROOT_PAGE_PATH)
  }, [])

  return (
    <div className="absolute left-2/4 top-2/4">
      <Loader fontSize={40} color="#4100FF" size="small" />
    </div>
  )
}

export default _404
